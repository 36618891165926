import { ChangeEvent } from 'react';
import { List, ListItemButton, ListItemText, TextField, Typography } from '@mui/material';
import block from 'bem-cn';
import { observer } from 'mobx-react';

import patientsStore from 'stores/patientsStore';

import { Switcher } from 'components';

import './PatientsList.scss';

const cnPatientsList = block('PatientsList');

interface PatientsListProps {
  dynamic?: boolean;
  switched?: boolean;
  setSwitched?: any;
}

const PatientsList = ({dynamic,switched,setSwitched}:PatientsListProps) => {
  const onChangeSearchValue = (event: ChangeEvent<HTMLInputElement>) => {
    patientsStore.setSearchValue(event.target.value);
  };
  console.log(patientsStore.patients,!!patientsStore.filteredPatients)

  return (
    <>
      <TextField
        className={cnPatientsList('searchInput').toString()}
        id="outlined-basic"
        label="Поиск по пациентам"
        variant="outlined"
        name="searchInput"
        size={'small'}
        value={patientsStore.searchValue}
        onChange={onChangeSearchValue}
      />
      {dynamic && <Switcher switched={switched} setSwitched={setSwitched}  />}

      {!!patientsStore.filteredPatients.length ? (
        <List
          className={cnPatientsList().toString()}
          component="nav"
          aria-label="secondary mailbox folder"
        >
          {(patientsStore.filteredPatients as unknown as []).map((patient:any) => {
            const onClick = () => {
              patientsStore.selectPatient(patient.id);
            };

            return (
              <ListItemButton
                key={patient.id}
                className={cnPatientsList('patient').toString()}
                selected={patientsStore.selectedPatientId === patient.id}
                onClick={onClick}
              >
                <ListItemText primary={patient.username} />
              </ListItemButton>
            );
          })}
        </List>
      ) : (
        <Typography>Нет данных</Typography>
      )}
    </>
  );
};

export default observer(PatientsList);
