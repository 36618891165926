import SettingsIcon from '@mui/icons-material/Settings';
import { Button } from '@mui/material';
import block from 'bem-cn';
import { observer } from 'mobx-react';

import { dashboardStore } from 'stores';

import {  testData } from 'pages/DashboardPage/DashboardPage';
import { PanelWrapper } from 'components';

import './PatientInfo.scss';

const cnPatientInfo = block('PatientInfo');

const PatientsSex = {
  1: 'Мужской',
  2: 'Женский',
};

const Traumas = {
  1: 'Инсульт',
  2: 'Травма верхней конечности',
  3:'Мононейропатия',
  4:'Полинейропатия',
  5:'Тунельный синдром',
  6:'Другое'
};



const PatientInfo = (props?: any) => {
  const onClick =()=>{
    dashboardStore.setGestureAdvanceSetting(!!dashboardStore.selectedGestureAdvanceSetting ? false : true);
    !dashboardStore.selectedGestureAdvanceSetting && dashboardStore.setSelectedGesture('bla',testData(dashboardStore.jointHand!));
  }
  
  return (
    <PanelWrapper className={cnPatientInfo()}>
      {props.patient ? (
        <>
          <div className={cnPatientInfo('row')}>
            Имя: <span>{props?.patient.first_name}</span>
          </div>
          <div className={cnPatientInfo('row')}>
            Фамилия: <span>{props?.patient.last_name}</span>
          </div>
          <div className={cnPatientInfo('row')}>
            Дата рождения:
            <span>
              {' ' + new Intl.DateTimeFormat('ru').format(new Date(props?.patient.date_of_birth))}
            </span>
          </div>
          <div className={cnPatientInfo('row')}>
            Пол: <span>{props?.patient.sex ?  PatientsSex[props?.patient.sex as keyof typeof PatientsSex] : 
            'Не указан'}</span>
          </div>
          <div className={cnPatientInfo('row')}>
            Оплата: <span>{props?.patient.pay ? 'Оплачено' : 'Не оплачено'}</span>
          </div>
          <div className={cnPatientInfo('row')}>
            Травма: <span>{Traumas[props?.patient.trauma as keyof typeof Traumas] ?? 'Нет данных'}</span>
          </div>
          <div className={cnPatientInfo('row')}>
            Другие травым: <span>{props?.patient.trauma_other ?? 'Нет данных'}</span>
          </div>
        </>
      ) : (
        'Нет данных о пациенте'
      )}
      {(!!dashboardStore.selectedGesture && props.tab =='gesture') && <Button variant="outlined" onClick={()=>onClick()} size='small'>{!dashboardStore.selectedGestureAdvanceSetting ?'Расширенные настройки': 'Упрощённые настройки'}<SettingsIcon /></Button>}
    </PanelWrapper>
  );
};

export default observer(PatientInfo);
