export enum MuscleHighlight {
  NoHighlight,
  HasHighlight,
}

export enum HandModelMeshNames {
  Anconeus = 'anconeus',
  Biceps = 'biceps',
  Brachialis = 'brachialis',
  Brachioradialis = 'brachioradialis',
  Coracobrachialis = 'coracobrachialis001',
  Deltoid = 'deltoid',
  ExtCarpiRadialisBrevis = 'ext_carpi_radialis_brevis',
  ExtCarpiRadialisLongus = 'ext_carpi_radialis_longus',
  ExtCarpiUlnaris = 'ext_carpi_ulnaris',
  ExtDigiMinimi = 'ext_digi_minimi',
  ExtDigitorum = 'ext_digitorum',
  FlexorCarpiRadialis = 'flexor_carpi_radialis',
  /* FlexorCarpiUlnaris = 'flexor_carpi_ulnaris', */
  Infraspinatus = 'infraspinatus',
  PalmarisLongus = 'Длинная_ладонная_мышца',
  Pectoralis = 'pectoralis001',
  PronatorTeres = 'pronator_teres',
  Skeleton = 'skeleton',
  Bones = 'Bones',
  TeresMajor = 'TERES_MAJOR',
  TeresMinor = 'teres_minor',
  Trapezius = 'trapezius',
  Triceps = 'triceps',
  DeepFlexFingers = 'Глубокий_сгибатель_пальцев_1',
  LongFlexBigFingerBone = 'Длинный_сгибатель_большого_пальца_кисти_1',
  RayFlexWirst = 'Лучевой_сгибатель_запястья_1',
  FlexorCarpiUlnaris = 'Локтевой_сгибатель_запястья_1',
  FlexordiGitorumSuperficialis = 'Поверхностный_сгибатель_пальцев_1',
  AbductorDigitiMiniMuscle = 'Мышца,_отводящая_мизинец_1',
  DeepFlexFingers2 = 'Глубокий_сгибатель_пальцев_2',
  LongFlexBigFingerBone2 = 'Длинный_сгибатель_большого_пальца_кисти_2',
  RayFlexWirst2 = 'Лучевой_сгибатель_запястья_2',
  FlexorCarpiUlnaris2 = 'Локтевой_сгибатель_запястья_2',
  FlexordiGitorumSuperficialis2 = 'Поверхностный_сгибатель_пальцев_2',
  AbductorDigitiMiniMuscle2 = 'Мышца,_отводящая_мизинец_2',
  Короткая_мышца_отводящая_большой_палец_кисти_1 = 'Короткая_мышца,_отводящая_большой_палец_кисти_1',
  Короткая_мышца_отводящая_большой_палец_кисти_2 = 'Короткая_мышца,_отводящая_большой_палец_кисти_2',
  Короткий_сгибатель_большого_пальца_кисти_1 = 'Короткий_сгибатель_большого_пальца_кисти_1',
  Короткий_сгибатель_большого_пальца_кисти_2 = 'Короткий_сгибатель_большого_пальца_кисти_2',
  Тыльные_межкостные_мышцы_1 = 'Тыльные_межкостные_мышцы_1',
  Тыльные_межкостные_мышцы_2 = 'Тыльные_межкостные_мышцы_2',
  Короткий_сгибатель_мизинца_1 = 'Короткий_сгибатель_мизинца_1',
  Короткий_сгибатель_мизинца_2 = 'Короткий_сгибатель_мизинца_2',
  Червеобразные_мышцы_1 = 'Червеобразные_мышцы_1',
  Червеобразные_мышцы_2 = 'Червеобразные_мышцы_2',
  Мышца_приводящая_большой_палец_косая_головка_1 = 'Мышца,_приводящая_большой_палец_-_косая_головка_1',
  Мышца_приводящая_большой_палец_косая_головка_2 = 'Мышца,_приводящая_большой_палец_-_косая_головка_2',
  Мышца_противопоставляющая_большой_палец_кисти_1 = 'Мышца,_противопоставляющая_большой_палец_кисти_1',
  Мышца_противопоставляющая_большой_палец_кисти_2 = 'Мышца,_противопоставляющая_большой_палец_кисти_2',
  Мышца_приводящая_большой_палец__поперечная_головка_1 = 'Мышца,_приводящая_большой_палец_-_поперечная_головка_1',
  Мышца_приводящая_большой_палец__поперечная_головка_2 = 'Мышца,_приводящая_большой_палец_-_поперечная_головка_2',
  Длинная_мышца_отводящая_большой_палец_кисти_1='Длинная_мышца,_отводящая_большой_палец_кисти_1',
  Длинная_мышца_отводящая_большой_палец_кисти_2='Длинная_мышца,_отводящая_большой_палец_кисти_2',
  Разгибатель_указательного_пальца_1='Разгибатель_указательного_пальца_1',
  Разгибатель_указательного_пальца_2='Разгибатель_указательного_пальца_2',
  Короткий_разгибатель_большого_пальца_кисти_1 = 'Короткий_разгибатель_большого_пальца_кисти_1',
  Короткий_разгибатель_большого_пальца_кисти_2 = 'Короткий_разгибатель_большого_пальца_кисти_2',
  Длинный_разгибатель_большого_пальца_кисти_1 = 'Длинный_разгибатель_большого_пальца_кисти_1',
  Длинный_разгибатель_большого_пальца_кисти_2 = 'Длинный_разгибатель_большого_пальца_кисти_2',
  Короткий_лучевой_разгибатель_запястья_1 = 'Короткий_лучевой_разгибатель_запястья_1',
  Короткий_лучевой_разгибатель_запястья_2 = 'Короткий_лучевой_разгибатель_запястья_2',
  Длинный_лучевой_разгибатель_запястья_1 = 'Длинный_лучевой_разгибатель_запястья_1',
  Длинный_лучевой_разгибатель_запястья_2='Длинный_лучевой_разгибатель_запястья_2',
  Разгибатель_мизинца_1 = 'Разгибатель_мизинца_1',
  Разгибатель_мизинца_2='Разгибатель_мизинца_2',
  Разгибатель_пальцев_1 ='Разгибатель_пальцев_1',
  Разгибатель_пальцев_2='Разгибатель_пальцев_2',
  Локтевой_разгибатель_запястья_1 = 'Локтевой_разгибатель_запястья_1',
  Локтевой_разгибатель_запястья_2='Локтевой_разгибатель_запястья_2',
}
