import { useEffect, useRef, useState } from 'react';
import block from 'bem-cn';
import { observer } from 'mobx-react';
import { Bar, BarChart, Legend, Line, LineChart, ReferenceLine, Tooltip, XAxis, YAxis } from 'recharts';

import { dashboardStore } from 'stores';

import './JointLineChart.scss';


const INDEX_KEY = 'index';

export enum Lines {
  DATA_KEY = 'Первая дата',
  DATA_KEY2 = 'Последняя дата',
  DATA_KEY3 = 'Средняя дата',
}

const cnJointLineChart = block('JointLineChart');

const JointLineChart = (props?:{tab:string | undefined}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const chartData2: any[] | undefined = [];
  const chartData3: any[] | undefined = [];
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const selectedJoint = dashboardStore.selectedJoint!;

  const linesArray = [
    [Lines.DATA_KEY,'#8fce00']
  ];

  if(!!selectedJoint.jointData2){
    linesArray.push([Lines.DATA_KEY3,'#6a329f'],[Lines.DATA_KEY2,'#8884d8'],)
  }

  useEffect(() => {
    if (containerRef.current) {
      setWidth(containerRef.current.offsetWidth);
      setHeight(containerRef.current.offsetHeight);
      console.log(containerRef.current.offsetWidth,containerRef.current.offsetHeight)
    }
  }, [containerRef,selectedJoint]);

  const chartData1: any[] = selectedJoint.jointData?.data.map((val: any, index: any) => {
    return {
      [Lines.DATA_KEY]: val,
      [INDEX_KEY]: index,
    };
  });
 if(!!selectedJoint.jointData2) {selectedJoint.jointData2?.data.map((val: any, index: any) => {
    chartData2.push({...chartData1[index],...{
      [Lines.DATA_KEY2]: val,
    }})
  });
  selectedJoint.jointData3?.data.map((val: any, index: any) => {
    chartData3.push({...chartData2[index],...{
      [Lines.DATA_KEY3]: val,
    }});
  });
}

  return (
    <>{!!props?.tab ? <div className={cnJointLineChart()} ref={containerRef}>
    <BarChart
      width={width}
      height={height}
      data={!!chartData3.length ? chartData3 : chartData1}
      margin={{
        top: 5,
        right: 10,
        left: 30,
        bottom: 20,
      }}
    >
      <XAxis
        dataKey={INDEX_KEY}
        ticks={selectedJoint.xAxisTicks}
        
      />
      <YAxis
        label={{
          value: selectedJoint.name,
          style: { textAnchor: 'middle' },
          angle: -90,
          position: 'left',
          offset: 10,
        }}
        domain={[
          parseFloat(
            (
              Math.min(selectedJoint.jointData?.min, selectedJoint.jointData?.lcl) -
              selectedJoint.jointData?.std * 0.5
            ).toFixed(2),
          ),
          parseFloat(
            (
              Math.max(selectedJoint.jointData?.max, selectedJoint.jointData?.ucl) +
              selectedJoint.jointData?.std * 0.5
            ).toFixed(2),
          ),
        ]}
      />
      {linesArray.map((data)=>{
        return (<Bar maxBarSize={150} dataKey={data[0]} fill={data[1]} />)
      })}

      <Legend />
    </BarChart>
  </div> :
    <div className={cnJointLineChart()} ref={containerRef}>
      <LineChart
        width={width}
        height={height}
        data={!!chartData3.length ? chartData3 : chartData1}
        margin={{
          top: 5,
          right: 10,
          left: 30,
          bottom: 20,
        }}
      >
        <XAxis
          label={{
            value: 'Номер точки',
            position: 'insideBottomRight',
          }}
          dataKey={INDEX_KEY}
          ticks={selectedJoint.xAxisTicks}
          
        />
        <YAxis
          label={{
            value: selectedJoint.name,
            style: { textAnchor: 'middle' },
            angle: -90,
            position: 'left',
            offset: 10,
          }}
          domain={[
            parseFloat(
              (
                Math.min(selectedJoint.jointData?.min, selectedJoint.jointData?.lcl) -
                selectedJoint.jointData?.std * 0.5
              ).toFixed(2),
            ),
            parseFloat(
              (
                Math.max(selectedJoint.jointData?.max, selectedJoint.jointData?.ucl) +
                selectedJoint.jointData?.std * 0.5
              ).toFixed(2),
            ),
          ]}
        />
        {linesArray.map((data)=>{
          return (<Line type="monotone" dataKey={data[0]} stroke={data[1]} />)
        })}

        <ReferenceLine
          y={selectedJoint.jointData?.ucl}
          label={{
            value: `Верхняя граница: ${selectedJoint.jointData?.ucl.toFixed(2)}`,
            dy: -16,
            position: 'insideRight',
          }}
          stroke="#82c7bd"
        />

        <ReferenceLine
          y={selectedJoint.jointData?.mean + selectedJoint.jointData?.std}
          label={{
            value: `(+) дисперсия: ${(
              selectedJoint.jointData?.mean + selectedJoint.jointData?.std
            ).toFixed(2)}`,
            dy: -16,
            position: 'insideRight',
          }}
          stroke="#82c7bd"
        />

        <ReferenceLine
          y={selectedJoint.jointData?.mean}
          label={{
            value: `Среднее значение: ${selectedJoint.jointData?.mean.toFixed(2)}`,
            dy: -16,
            position: 'insideRight',
          }}
          stroke="#82c7bd"
        />

        <ReferenceLine
          y={selectedJoint.jointData?.mean - selectedJoint.jointData?.std}
          label={{
            value: `(-) дисперсия: ${(
              selectedJoint.jointData?.mean - selectedJoint.jointData?.std
            ).toFixed(2)}`,
            dy: -16,
            position: 'insideRight',
          }}
          stroke="#82c7bd"
        />

        <ReferenceLine
          y={selectedJoint.jointData?.lcl}
          label={{
            value: `Нижняя граница: ${selectedJoint.jointData?.lcl.toFixed(2)}`,
            dy: -16,
            position: 'insideRight',
          }}
          stroke="#82c7bd"
        />

        <Tooltip />
        <Legend />
      </LineChart>
    </div>
    }</>
  );
};

export default observer(JointLineChart);
