/* eslint-disable no-shadow */
import React, { useRef } from 'react';
import { observer } from 'mobx-react';
import { Cell, Legend, Pie, PieChart } from 'recharts';

import { dashboardStore } from 'stores';

const RADIAN = Math.PI / 180;
const data = [
  { name: 'Критические показатели', value: 5, color: '#ff0000' },
  { name: 'Пониженная функциональность', value: 3, color: '#FFA500' },
  { name: 'Норма', value: 2, color: '#00ff00' },
];
const cx = 240;
const cy = 100;
const iR = 50;
const oR = 100;

const needle = (
  value: number,
  data: any[],
  cx: number,
  cy: number,
  iR: number,
  oR: number,
  color: string | undefined,
) => {
  let total = 0;
  data.forEach((v) => {
    total += v.value;
  });
  const ang = 180.0 * (1 - value / total);
  const length = (iR + 2 * oR) / 3;
  const sin = Math.sin(-RADIAN * ang);
  const cos = Math.cos(-RADIAN * ang);
  const r = 5;
  const x0 = cx + 5;
  const y0 = cy + 5;
  const xba = x0 + r * sin;
  const yba = y0 - r * cos;
  const xbb = x0 - r * sin;
  const ybb = y0 + r * cos;
  const xp = x0 + length * cos;
  const yp = y0 + length * sin;

  return [
    <circle cx={x0} cy={y0} r={r} fill={color} stroke="none" />,
    <path
      d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`}
      stroke="#none"
      fill={color}
    />,
  ];
};


const Example = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const value =
    dashboardStore.selectedGesture?.jointData[1].CountDate2 ??
    dashboardStore.selectedGesture?.jointData[1].Count;
  return (
    <div ref={containerRef}>
      <PieChart className='pie' width={500} height={110}>
        <Pie
          dataKey="value"
          startAngle={180}
          endAngle={0}
          data={data}
          cx={cx}
          cy={cy}
          innerRadius={iR}
          outerRadius={oR}
          fill="#8884d8"
          stroke="none"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
          
        </Pie>
        <Legend layout="horizontal" verticalAlign="bottom" align="center" />
        {needle(value, data, cx, cy, iR, oR, 'gray')}
      </PieChart>
      <div className="description">
        <div className='dscr'>Результат: {Math.round(value)} из 10 баллов</div>
        <div style={{  }}>
          Увлечение показателей на:{' '}
          <span style={{ fontSize: 25, color: 'green' }}>
            {Math.round(
              dashboardStore.selectedGesture?.jointData[1].CountDate2 -
                dashboardStore.selectedGesture?.jointData[1].Count,
            )}{' '} 
            %
          </span>
        </div>
      </div>
    </div>
  );
};
export default observer(Example);