import { List, ListItemButton, ListItemText } from '@mui/material';
import block from 'bem-cn';
import { observer } from 'mobx-react';

import patientsStore from 'stores/patientsStore';

import './AttemptList.scss';

const cnAttemptsList = block('AttemptsList');

const AttemptsList = () => {
  const onClick = (attempt: number) => {
    patientsStore.selectPatientAttempt(attempt);
  };

  return (
    <>
      {!!patientsStore.patientDataAttempt?.length && (
        <List className={cnAttemptsList('nav').toString()}>
          Попытки:
          {patientsStore.patientDataAttempt.map(
            (UserDateAttempt: { [attempt: string]: number }, i: number) => {
              return (
                <ListItemButton
                  key={'_.' + UserDateAttempt['attempt' + i] + Math.random()}
                  className={cnAttemptsList('Attempts').toString()}
                  selected={patientsStore.selectedPatientAttempt === UserDateAttempt['attempt' + i]}
                  onClick={() => onClick(UserDateAttempt['attempt' + i])}
                >
                  <ListItemText primary={`${i + 1}`} />
                </ListItemButton>
              );
            },
          )}
        </List>
      )}
    </>
  );
};

export default observer(AttemptsList);
