/**
 * UNGROUPED CONSTANTS
 */

export const TIME = 'Время';
export const HAND_PRONATION = 'Пронация кисти';

/**
 * JOINT TYPES
 */

export const WRIST_ABDUCTION_JOINT_TYPE = 'Отведение кисти';
export const HAND_FLEXION_JOINT_TYPE = 'Сгибание кисти';
export const THUMB_FLEXION_PF_JOINT_TYPE = 'Сгибание большого пальца ПФ';
export const THUMB_ABDUCTION_PF_JOINT_TYPE = 'Отведение большого пальца ПФ';
export const THUMB_FLEXION_P_JOINT_TYPE = 'Сгибание большого пальца П';
export const THUMB_FLEXION_D_JOINT_TYPE = 'Сгибание большого пальца Д';
export const CARPOMETACARPAL_FLEXION_JOINT_TYPE = 'Сгибание запястно-пястного сустава';
export const INDEX_FINGER_ABDUCTION_PF_JOINT_TYPE = 'Отведение указательного пальца ПФ';
export const INDEX_FINGER_FLEXION_PF_JOINT_TYPE = 'Сгибание указательного пальца ПФ';
export const INDEX_FINGER_FLEXION_P_JOINT_TYPE = 'Сгибание указательного пальца П';
export const INDEX_FINGER_FLEXION_D_JOINT_TYPE = 'Сгибание указательного пальца Д';
export const MIDDLE_FINGER_ABDUCTION_PF_JOINT_TYPE = 'Отведение среднего пальца ПФ';
export const MIDDLE_FINGER_FLEXION_PF_JOINT_TYPE = 'Сгибание среднего пальца ПФ';
export const MIDDLE_FINGER_FLEXION_P_JOINT_TYPE = 'Сгибание среднего пальца П';
export const MIDDLE_FINGER_FLEXION_D_JOINT_TYPE = 'Сгибание среднего пальца Д';
export const RING_FINGER_ABDUCTION_PF_JOINT_TYPE = 'Отведение безымянного пальца ПФ';
export const RING_FINGER_FLEXION_PF_JOINT_TYPE = 'Сгибание безымянного пальца ПФ';
export const RING_FINGER_FLEXION_P_JOINT_TYPE = 'Сгибание безымянного пальца П';
export const RING_FINGER_FLEXION_D_JOINT_TYPE = 'Сгибание безымянного пальца Д';
export const LITTLE_FINGER_ABDUCTION_PF_JOINT_TYPE = 'Отведение мизинца ПФ';
export const LITTLE_FINGER_FLEXION_PF_JOINT_TYPE = 'Сгибание мизинца ПФ';
export const LITTLE_FINGER_FLEXION_P_JOINT_TYPE = 'Сгибание мизинца П';
export const LITTLE_FINGER_FLEXION_D_JOINT_TYPE = 'Сгибание мизинца Д';

/**
 * MUSCLES
 */

export const EXTENSOR_CARPI_RADIALIS_LONGUS = 'Длинный лучевой разгибатель запястья';
export const EXTENSOR_CARPI_RADIALIS_BREVIS = 'Короткий лучевой разгибатель запястья';
export const EXTENSOR_CARPI_ULNARIS = 'Локтевой разгибатель запястья';
export const FLEXOR_CARPI_RADIALIS = 'Лучевой сгибатель запястья';
export const FLEXOR_CARPI_ULNARIS = 'Локтевой сгибатель запястья';
export const PALMARIS_LONGUS_MUSCLE = 'Длинная ладонная мышца';
export const FLEXOR_DIGITORUM_SUPERFICIALIS_TENDON_TO_THE_LITTLE_FINGER =
  'Поверхностный сгибатель пальцев - сухожилие к мизинцу';
export const FLEXOR_DIGITORUM_SUPERFICIALIS_TENDON_TO_THE_RING_FINGER =
  'Поверхностный сгибатель пальцев - сухожилие к безымянному';
export const FLEXOR_DIGITORUM_SUPERFICIALIS_TENDON_TO_THE_MIDDLE_FINGER =
  'Поверхностный сгибатель пальцев - сухожилие к среднему';
export const FLEXOR_DIGITORUM_SUPERFICIALIS_TENDON_TO_THE_INDEX_FINGER =
  'Поверхностный сгибатель пальцев - cухожилие к указательному пальцу';
export const FLEXOR_DIGITORUM_PROFUNDUS_TENDON_TO_THE_LITTLE_FINGER =
  'Глубокий сгибатель пальцев  - сухожилие к мизинцу';
export const FLEXOR_DIGITORUM_PROFUNDUS_TENDON_TO_THE_RING_FINGER =
  'Глубокий сгибатель пальцев - сухожилие к безымянному';
export const FLEXOR_DIGITORUM_PROFUNDUS_TENDON_TO_THE_MIDDLE_FINGER =
  'Глубокий сгибатель пальцев - сухожилие к среднему';
export const FLEXOR_DIGITORUM_PROFUNDUS_TENDON_TO_THE_INDEX_FINGER =
  'Глубокий сгибатель пальцев - cухожилие к указательному пальцу';
export const EXTENSOR_DIGITORUM_TENDON_TO_THE_LITTLE_FINGER =
  'Разгибатель пальцев  - сухожилие к мизинцу';
export const EXTENSOR_DIGITORUM_TENDON_TO_THE_RING_FINGER =
  'Разгибатель пальцев  - сухожилие к безымянному';
export const EXTENSOR_DIGITORUM_TENDON_TO_THE_MIDDLE_FINGER =
  'Разгибатель пальцев  - сухожилие к среднему';
export const EXTENSOR_DIGITORUM_TENDON_TO_THE_INDEX_FINGER =
  'Разгибатель пальцев  - сухожилие к указательному';
export const EXTENSOR_OF_THE_LITTLE_FINGER = 'Разгибатель мизинца';
export const EXTENSOR_OF_THE_INDEX_FINGER = 'Разгибатель указательного пальца';
export const EXTENSOR_POLLICIS_LONGUS = 'Длинный разгибатель большого пальца кисти';
export const EXTENSOR_POLLICIS_BREVIS = 'Короткий разгибатель большого пальца кисти';
export const FLEXOR_POLLICIS_LONGUS = 'Длинный сгибатель большого пальца кисти';
export const ABDUCTOR_POLLICIS_LONGUS_MUSCLE = 'Длинная мышца, отводящая большой палец кисти';
export const ABDUCTOR_POLLICIS_BREVIS_MUSCLE = 'Короткая мышца, отводящая большой палец кисти';
export const FLEXOR_POLLICIS_BREVIS = 'Короткий сгибатель большого пальца кисти';
export const MUSCLE_THAT_OPPOSES_THE_THUMB_TO_THE_HAND =
  'Мышца, противопоставляющая большой палец кисти';
export const ADDUCTOR_POLLICIS_MUSCLE_TRANSVERSE_HEAD =
  'Мышца, приводящая большой палец - поперечная головка';
export const ADDUCTOR_POLLICIS_MUSCLE_OBLIQUE_HEAD =
  'Мышца, приводящая большой палец - косая головка';
export const ABDUCTOR_DIGITI_MINIMI_MUCLE = 'Мышца, отводящая мизинец';
export const FLEXOR_DIGITI_BREVIS = 'Короткий сгибатель мизинца';
export const FIRST_PALMAR_INTEROSSEOUS_MUSCLE = 'Первая ладонная межкостная мышца';
export const SECOND_PALMAR_INTEROSSEOUS_MUSCLE = 'Вторая ладонная межкостная мышца';
export const THIRD_PALMAR_INTEROSSEOUS_MUSCLE = 'Третья ладонная межкостная мышца';
export const FIRST_DORSAL_INTEROSSEOUS_MUSCLE_1 = 'Первая тыльная межкостная мышца (1)';
export const FIRST_DORSAL_INTEROSSEOUS_MUSCLE_2 = 'Первая тыльная межкостная мышца (2)';
export const SECOND_DORSAL_INTEROSSEOUS_MUSCLE = 'Вторая тыльная межкостная мышца';
export const THIRD_DORSAL_INTEROSSEOUS_MUSCLE = 'Третья тыльная межкостная мышца';
export const FOURTH_DORSAL_INTEROSSEOUS_MUSCLE = 'Четвертая тыльная межкостная мышца';
export const VERMIFORM_MUSCLE_TO_THE_LITTLE_FINGER = 'Червеобразная мышца - к мизинцу';
export const VERMIFORM_MUSCLE_TO_THE_RING_FINGER = 'Червеобразная мышца - к безымянному';
export const VERMIFORM_MUSCLE_TO_THE_MIDDLE_FINGER = 'Червеобразная мышца - к среднему';
export const VERMIFORM_MUSCLE_TO_THE_INDEX_FINGER = 'Червеобразная мышца - к указательному';

export const JOINTS = [
  WRIST_ABDUCTION_JOINT_TYPE,
  HAND_FLEXION_JOINT_TYPE,
  THUMB_FLEXION_PF_JOINT_TYPE,
  THUMB_ABDUCTION_PF_JOINT_TYPE,
  THUMB_FLEXION_P_JOINT_TYPE,
  THUMB_FLEXION_D_JOINT_TYPE,
  CARPOMETACARPAL_FLEXION_JOINT_TYPE,
  INDEX_FINGER_ABDUCTION_PF_JOINT_TYPE,
  INDEX_FINGER_FLEXION_PF_JOINT_TYPE,
  INDEX_FINGER_FLEXION_P_JOINT_TYPE,
  INDEX_FINGER_FLEXION_D_JOINT_TYPE,
  MIDDLE_FINGER_ABDUCTION_PF_JOINT_TYPE,
  MIDDLE_FINGER_FLEXION_PF_JOINT_TYPE,
  MIDDLE_FINGER_FLEXION_P_JOINT_TYPE,
  MIDDLE_FINGER_FLEXION_D_JOINT_TYPE,
  RING_FINGER_ABDUCTION_PF_JOINT_TYPE,
  RING_FINGER_FLEXION_PF_JOINT_TYPE,
  RING_FINGER_FLEXION_P_JOINT_TYPE,
  RING_FINGER_FLEXION_D_JOINT_TYPE,
  LITTLE_FINGER_ABDUCTION_PF_JOINT_TYPE,
  LITTLE_FINGER_FLEXION_PF_JOINT_TYPE,
  LITTLE_FINGER_FLEXION_P_JOINT_TYPE,
  LITTLE_FINGER_FLEXION_D_JOINT_TYPE,
];

export const jointsToMuscles = new Map([
  [
    WRIST_ABDUCTION_JOINT_TYPE,
    [
      EXTENSOR_CARPI_RADIALIS_LONGUS,
      EXTENSOR_CARPI_RADIALIS_BREVIS,
      EXTENSOR_CARPI_ULNARIS,
      FLEXOR_CARPI_RADIALIS,
      FLEXOR_CARPI_ULNARIS,
      PALMARIS_LONGUS_MUSCLE,
    ],
  ],
  [
    HAND_FLEXION_JOINT_TYPE,
    [
      EXTENSOR_CARPI_RADIALIS_LONGUS,
      EXTENSOR_CARPI_RADIALIS_BREVIS,
      EXTENSOR_CARPI_ULNARIS,
      FLEXOR_CARPI_RADIALIS,
      FLEXOR_CARPI_ULNARIS,
      PALMARIS_LONGUS_MUSCLE,
    ],
  ],
  [
    THUMB_FLEXION_PF_JOINT_TYPE,
    [
      EXTENSOR_POLLICIS_LONGUS,
      EXTENSOR_POLLICIS_BREVIS,
      FLEXOR_POLLICIS_LONGUS,
      ABDUCTOR_POLLICIS_LONGUS_MUSCLE,
      ABDUCTOR_POLLICIS_BREVIS_MUSCLE,
      FLEXOR_POLLICIS_BREVIS,
      MUSCLE_THAT_OPPOSES_THE_THUMB_TO_THE_HAND,
      ADDUCTOR_POLLICIS_MUSCLE_TRANSVERSE_HEAD,
      ADDUCTOR_POLLICIS_MUSCLE_OBLIQUE_HEAD,
      FIRST_DORSAL_INTEROSSEOUS_MUSCLE_1,
    ],
  ],
  [
    THUMB_ABDUCTION_PF_JOINT_TYPE,
    [
      EXTENSOR_POLLICIS_LONGUS,
      EXTENSOR_POLLICIS_BREVIS,
      FLEXOR_POLLICIS_LONGUS,
      ABDUCTOR_POLLICIS_LONGUS_MUSCLE,
      ABDUCTOR_POLLICIS_BREVIS_MUSCLE,
      FLEXOR_POLLICIS_BREVIS,
      MUSCLE_THAT_OPPOSES_THE_THUMB_TO_THE_HAND,
      ADDUCTOR_POLLICIS_MUSCLE_TRANSVERSE_HEAD,
      ADDUCTOR_POLLICIS_MUSCLE_OBLIQUE_HEAD,
      FIRST_DORSAL_INTEROSSEOUS_MUSCLE_1,
    ],
  ],
  [
    THUMB_FLEXION_P_JOINT_TYPE,
    [
      EXTENSOR_POLLICIS_LONGUS,
      EXTENSOR_POLLICIS_BREVIS,
      FLEXOR_POLLICIS_LONGUS,
      ABDUCTOR_POLLICIS_BREVIS_MUSCLE,
      FLEXOR_POLLICIS_BREVIS,
      ADDUCTOR_POLLICIS_MUSCLE_TRANSVERSE_HEAD,
      ADDUCTOR_POLLICIS_MUSCLE_OBLIQUE_HEAD,
    ],
  ],
  [THUMB_FLEXION_D_JOINT_TYPE, [EXTENSOR_POLLICIS_LONGUS, FLEXOR_POLLICIS_LONGUS]],
  [
    CARPOMETACARPAL_FLEXION_JOINT_TYPE,
    [
      FLEXOR_DIGITORUM_SUPERFICIALIS_TENDON_TO_THE_LITTLE_FINGER,
      FLEXOR_DIGITORUM_SUPERFICIALIS_TENDON_TO_THE_RING_FINGER,
      FLEXOR_DIGITORUM_PROFUNDUS_TENDON_TO_THE_LITTLE_FINGER,
      FLEXOR_DIGITORUM_PROFUNDUS_TENDON_TO_THE_RING_FINGER,
      EXTENSOR_DIGITORUM_TENDON_TO_THE_LITTLE_FINGER,
      EXTENSOR_DIGITORUM_TENDON_TO_THE_RING_FINGER,
      EXTENSOR_OF_THE_LITTLE_FINGER,
      FIRST_PALMAR_INTEROSSEOUS_MUSCLE,
      FOURTH_DORSAL_INTEROSSEOUS_MUSCLE,
      VERMIFORM_MUSCLE_TO_THE_LITTLE_FINGER,
    ],
  ],
  [
    INDEX_FINGER_ABDUCTION_PF_JOINT_TYPE,
    [
      FLEXOR_DIGITORUM_SUPERFICIALIS_TENDON_TO_THE_MIDDLE_FINGER,
      FLEXOR_DIGITORUM_PROFUNDUS_TENDON_TO_THE_MIDDLE_FINGER,
      EXTENSOR_DIGITORUM_TENDON_TO_THE_MIDDLE_FINGER,
      EXTENSOR_OF_THE_LITTLE_FINGER,
      FLEXOR_DIGITI_BREVIS,
      THIRD_PALMAR_INTEROSSEOUS_MUSCLE,
      FIRST_DORSAL_INTEROSSEOUS_MUSCLE_1,
      VERMIFORM_MUSCLE_TO_THE_MIDDLE_FINGER,
    ],
  ],
  [
    INDEX_FINGER_FLEXION_PF_JOINT_TYPE,
    [
      FLEXOR_DIGITORUM_SUPERFICIALIS_TENDON_TO_THE_MIDDLE_FINGER,
      FLEXOR_DIGITORUM_PROFUNDUS_TENDON_TO_THE_MIDDLE_FINGER,
      EXTENSOR_DIGITORUM_TENDON_TO_THE_MIDDLE_FINGER,
      EXTENSOR_OF_THE_LITTLE_FINGER,
      FLEXOR_DIGITI_BREVIS,
      THIRD_PALMAR_INTEROSSEOUS_MUSCLE,
      FIRST_DORSAL_INTEROSSEOUS_MUSCLE_1,
      VERMIFORM_MUSCLE_TO_THE_MIDDLE_FINGER,
    ],
  ],
  [
    INDEX_FINGER_FLEXION_P_JOINT_TYPE,
    [
      FLEXOR_DIGITORUM_SUPERFICIALIS_TENDON_TO_THE_MIDDLE_FINGER,
      FLEXOR_DIGITORUM_PROFUNDUS_TENDON_TO_THE_MIDDLE_FINGER,
      EXTENSOR_DIGITORUM_TENDON_TO_THE_MIDDLE_FINGER,
      EXTENSOR_OF_THE_LITTLE_FINGER,
    ],
  ],
  [
    INDEX_FINGER_FLEXION_D_JOINT_TYPE,
    [
      FLEXOR_DIGITORUM_PROFUNDUS_TENDON_TO_THE_MIDDLE_FINGER,
      EXTENSOR_DIGITORUM_TENDON_TO_THE_MIDDLE_FINGER,
      EXTENSOR_OF_THE_LITTLE_FINGER,
    ],
  ],
  [
    MIDDLE_FINGER_ABDUCTION_PF_JOINT_TYPE,
    [
      FLEXOR_DIGITORUM_SUPERFICIALIS_TENDON_TO_THE_RING_FINGER,
      FLEXOR_DIGITORUM_PROFUNDUS_TENDON_TO_THE_RING_FINGER,
      EXTENSOR_DIGITORUM_TENDON_TO_THE_RING_FINGER,
      FIRST_DORSAL_INTEROSSEOUS_MUSCLE_2,
      SECOND_DORSAL_INTEROSSEOUS_MUSCLE,
      VERMIFORM_MUSCLE_TO_THE_RING_FINGER,
    ],
  ],
  [
    MIDDLE_FINGER_FLEXION_PF_JOINT_TYPE,
    [
      FLEXOR_DIGITORUM_SUPERFICIALIS_TENDON_TO_THE_RING_FINGER,
      FLEXOR_DIGITORUM_PROFUNDUS_TENDON_TO_THE_RING_FINGER,
      EXTENSOR_DIGITORUM_TENDON_TO_THE_RING_FINGER,
      FIRST_DORSAL_INTEROSSEOUS_MUSCLE_2,
      SECOND_DORSAL_INTEROSSEOUS_MUSCLE,
      VERMIFORM_MUSCLE_TO_THE_RING_FINGER,
    ],
  ],
  [
    MIDDLE_FINGER_FLEXION_P_JOINT_TYPE,
    [
      FLEXOR_DIGITORUM_SUPERFICIALIS_TENDON_TO_THE_RING_FINGER,
      FLEXOR_DIGITORUM_PROFUNDUS_TENDON_TO_THE_RING_FINGER,
      EXTENSOR_DIGITORUM_TENDON_TO_THE_RING_FINGER,
    ],
  ],
  [
    MIDDLE_FINGER_FLEXION_D_JOINT_TYPE,
    [
      FLEXOR_DIGITORUM_PROFUNDUS_TENDON_TO_THE_RING_FINGER,
      EXTENSOR_DIGITORUM_TENDON_TO_THE_RING_FINGER,
    ],
  ],
  [
    RING_FINGER_ABDUCTION_PF_JOINT_TYPE,
    [
      FLEXOR_DIGITORUM_SUPERFICIALIS_TENDON_TO_THE_LITTLE_FINGER,
      FLEXOR_DIGITORUM_PROFUNDUS_TENDON_TO_THE_LITTLE_FINGER,
      EXTENSOR_DIGITORUM_TENDON_TO_THE_LITTLE_FINGER,
      FIRST_PALMAR_INTEROSSEOUS_MUSCLE,
      THIRD_DORSAL_INTEROSSEOUS_MUSCLE,
      VERMIFORM_MUSCLE_TO_THE_LITTLE_FINGER,
    ],
  ],
  [
    RING_FINGER_FLEXION_PF_JOINT_TYPE,
    [
      FLEXOR_DIGITORUM_SUPERFICIALIS_TENDON_TO_THE_LITTLE_FINGER,
      FLEXOR_DIGITORUM_PROFUNDUS_TENDON_TO_THE_LITTLE_FINGER,
      EXTENSOR_DIGITORUM_TENDON_TO_THE_LITTLE_FINGER,
      FIRST_PALMAR_INTEROSSEOUS_MUSCLE,
      THIRD_DORSAL_INTEROSSEOUS_MUSCLE,
      VERMIFORM_MUSCLE_TO_THE_LITTLE_FINGER,
    ],
  ],
  [
    RING_FINGER_FLEXION_P_JOINT_TYPE,
    [
      FLEXOR_DIGITORUM_PROFUNDUS_TENDON_TO_THE_LITTLE_FINGER,
      EXTENSOR_DIGITORUM_TENDON_TO_THE_LITTLE_FINGER,
    ],
  ],
  [
    RING_FINGER_FLEXION_D_JOINT_TYPE,
    [
      FLEXOR_DIGITORUM_SUPERFICIALIS_TENDON_TO_THE_LITTLE_FINGER,
      FLEXOR_DIGITORUM_PROFUNDUS_TENDON_TO_THE_LITTLE_FINGER,
      EXTENSOR_DIGITORUM_TENDON_TO_THE_LITTLE_FINGER,
    ],
  ],
  [
    LITTLE_FINGER_ABDUCTION_PF_JOINT_TYPE,
    [
      PALMARIS_LONGUS_MUSCLE,
      FLEXOR_DIGITORUM_SUPERFICIALIS_TENDON_TO_THE_INDEX_FINGER,
      FLEXOR_DIGITORUM_PROFUNDUS_TENDON_TO_THE_INDEX_FINGER,
      EXTENSOR_DIGITORUM_TENDON_TO_THE_INDEX_FINGER,
      ADDUCTOR_POLLICIS_MUSCLE_OBLIQUE_HEAD,
      ABDUCTOR_DIGITI_MINIMI_MUCLE,
      SECOND_PALMAR_INTEROSSEOUS_MUSCLE,
      FOURTH_DORSAL_INTEROSSEOUS_MUSCLE,
    ],
  ],
  [
    LITTLE_FINGER_FLEXION_PF_JOINT_TYPE,
    [
      PALMARIS_LONGUS_MUSCLE,
      FLEXOR_DIGITORUM_SUPERFICIALIS_TENDON_TO_THE_INDEX_FINGER,
      FLEXOR_DIGITORUM_PROFUNDUS_TENDON_TO_THE_INDEX_FINGER,
      EXTENSOR_DIGITORUM_TENDON_TO_THE_INDEX_FINGER,
      ADDUCTOR_POLLICIS_MUSCLE_OBLIQUE_HEAD,
      ABDUCTOR_DIGITI_MINIMI_MUCLE,
      SECOND_PALMAR_INTEROSSEOUS_MUSCLE,
      FOURTH_DORSAL_INTEROSSEOUS_MUSCLE,
    ],
  ],
  [
    LITTLE_FINGER_FLEXION_P_JOINT_TYPE,
    [
      FLEXOR_DIGITORUM_SUPERFICIALIS_TENDON_TO_THE_INDEX_FINGER,
      FLEXOR_DIGITORUM_PROFUNDUS_TENDON_TO_THE_INDEX_FINGER,
      EXTENSOR_DIGITORUM_TENDON_TO_THE_INDEX_FINGER,
    ],
  ],
  [
    LITTLE_FINGER_FLEXION_D_JOINT_TYPE,
    [
      PALMARIS_LONGUS_MUSCLE,
      FLEXOR_DIGITORUM_SUPERFICIALIS_TENDON_TO_THE_INDEX_FINGER,
      FLEXOR_DIGITORUM_PROFUNDUS_TENDON_TO_THE_INDEX_FINGER,
      EXTENSOR_DIGITORUM_TENDON_TO_THE_INDEX_FINGER,
    ],
  ],
]);
