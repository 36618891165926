import * as React from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import block from 'bem-cn';

import { dashboardStore } from 'stores';

import './Switcher.scss';

const cnSwitcher = block('Switcher');
interface SwitcherProps {
  switched?: boolean;
  setSwitched?: any;
}

export default function Switcher({switched,setSwitched}:SwitcherProps) {

  const handlerSwitch  = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSwitched(event.target.checked);
    dashboardStore.setSwitcher(switched ?? false);
  };

  return (
    <div className={cnSwitcher()}>
      <FormControlLabel
          control={<Switch checked={switched} onChange={handlerSwitch} name="Switch" />}
          label="Выбрать определённую дату(1 график)"
        />
    </div>
  );
}
