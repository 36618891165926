import { TableBody, TableCell, TableRow } from '@mui/material';
import block from 'bem-cn';
import { observer } from 'mobx-react';

import { dashboardStore } from 'stores';

import { MathUtils } from 'shared/utils/mathUtils';

import './GestureTable.scss';


const cnGestureTable = block('GestureTable');

const GestureTable = () => {
  const GestureJOINTS = dashboardStore.GestureLoader.gestTable;

  return (
    <TableBody>
      {GestureJOINTS.map((joint, index) => {
        const jointsValues =[
          dashboardStore.GestureLoader.data[joint],
          dashboardStore.GestureLoader2.data[joint],
          dashboardStore.GestureLoader3.data[joint]
        ]
       
        if (!jointsValues[0]) return null;
        if (!jointsValues[1] && !jointsValues[2]) jointsValues.length = 1
        
       const data = jointsValues.map((jointValues)=>{
          if (!!jointValues) {
            return MathUtils.getSummaryStats(jointValues.data)
          }
        });

        const selectRow = () => {
          dashboardStore.selectedGestureAdvanceSetting && dashboardStore.setSelectedGesture(joint, data[0]!,data[1]!,data[2]!);
        };
/* console.log(data) */
        return (
          <TableRow
            data-tooltip={!dashboardStore.selectedGestureAdvanceSetting ?"Включите расширенные настройки": undefined}
            key={joint + index}
            onClick={selectRow}
            className={cnGestureTable('row', {
              selected: dashboardStore.selectedJoint?.name === joint,
            }).toString()}
          >
            <TableCell>{joint}</TableCell>
            {data.map((data:any)=>{
               return  <TableCell align="center">{data.median}</TableCell>
            })} 
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default observer(GestureTable);
