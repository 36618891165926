/* eslint-disable prefer-const */
import { makeAutoObservable } from 'mobx';
import {
  Box3,
  Color,
  Intersection,
  Mesh,
  MeshPhysicalMaterial,
  PointLight,
  Vector3,
  Vector4,
} from 'three';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import { sceneStore } from 'stores';

import { HandModelMeshNames, MuscleHighlight } from 'shared/enums';
import { ISceneStore } from 'shared/interfaces';
import { MuscleMaterial } from 'shared/materials';
import { ColorUtils } from 'shared/utils';

const modelUrl = '/models/19.glb';

export class HandProcessingModel {
  private sceneStore: ISceneStore;

  private modelStructures: Record<string, Mesh> = {};

  constructor(sceneStore: ISceneStore) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.sceneStore = sceneStore;
  }

  public init(lights: PointLight[]) {
    const loader = new GLTFLoader();
    const dracoLoader = new DRACOLoader();

    dracoLoader.setDecoderPath('/js/libs/draco-new/');
    loader.setDRACOLoader(dracoLoader);

    loader.load(modelUrl, (gltf) => {
      const modelData: any = gltf.scene;
      /* console.log(gltf); */
      this.sceneStore.scene.add(modelData);
      const bb = new Box3();

      const lightsInfo = lights.map((light) => ({
        position: light.position.clone(),
        color: new Vector3(light.color.r, light.color.g, light.color.b),
        intensity: light.intensity,
      }));   
      const accamulateResults:any = [];
      for (const child of modelData.children) {
      
        const collectIsMeshObjects=(obj: any): any[]=> {
          let result: any = [];
          // Проверяем, есть ли свойство isMesh у текущего объекта
          if (obj.isMesh) {
            result.push(obj);
          }
        
          // Если есть свойство children и это массив, проходимся по каждому элементу
          if (Array.isArray(obj.children)) {
            for (let child of obj.children) {
              // Рекурсивно собираем объекты с isMesh из дочерних объектов
              result = result.concat(collectIsMeshObjects(child));
            }
          }
          
          return result;
        }
  
        console.log(collectIsMeshObjects(child),accamulateResults);
          for(const mesh of collectIsMeshObjects(child)) {
            bb.expandByObject(mesh);
            const currentMesh = mesh as Mesh;
            accamulateResults.push(mesh);
            if (currentMesh.name === HandModelMeshNames.Bones) {
              currentMesh.material = new MeshPhysicalMaterial({
                color: new Color(0.89, 0.85, 0.79),
                reflectivity: 0.5,
                roughness: 0.4,
              });
  
              continue;
            }
            sceneStore.setMuscle(mesh.name);
  
            if (mesh.name === sceneStore.muscle) {
              currentMesh.material = new MuscleMaterial(new Vector4(0, 0, 139, 1.0), lightsInfo);
              this.sceneStore.outlinePass!.selectedObjects = [currentMesh];
            } else {
              
              const randomColor = ColorUtils.getRandomColorExcludingBlue();
  
              currentMesh.material = new MuscleMaterial(
                new Vector4(randomColor[0], randomColor[1], randomColor[2], 1.0),
                lightsInfo,
              );
              
            }
            this.modelStructures[currentMesh.name] = currentMesh;

            mesh.traverse((node: { isMesh: any; geometry: any; material: any; }) => {
              if (node.isMesh) {
                console.log(node.geometry);
                console.log(node.material);
              }
            });
          }
         
        }
        
        console.log(accamulateResults)
      this.sceneStore.zoomTo(bb);
    });
  }

  updateModelByBtns(name: string) {
    this.clearPrevSelectedObjectHighlight();
    sceneStore.setActiveMuscle(name);
    const selectedObject = this.sceneStore.handModel.modelStructures[name];
    this.sceneStore.outlinePass!.selectedObjects = [selectedObject];
    const material = selectedObject.material as MuscleMaterial;
    if (selectedObject.name !== name) {
      this.clearPrevSelectedObjectHighlight();
    } else {
      material.updateHighlight(MuscleHighlight.HasHighlight);
      console.log(selectedObject, this.sceneStore, name);
    }
  }

  private clearPrevSelectedObjectHighlight() {
    if (this.sceneStore.outlinePass && this.sceneStore.outlinePass.selectedObjects.length) {
      const object = this.sceneStore.outlinePass.selectedObjects[0] as Mesh;
      const material = object.material as MuscleMaterial;

      if (object.name !== HandModelMeshNames.Skeleton)
        material.updateHighlight(MuscleHighlight.NoHighlight);
    }
  }

  updateModelByIntersections(intersects: Intersection[], name?: string) {
    if (!this.sceneStore.outlinePass) return;

    if (intersects.length > 0) {
      this.clearPrevSelectedObjectHighlight();

      const selectedObject = intersects[0].object as Mesh;

      const material = selectedObject.material as MuscleMaterial;
      if (selectedObject.name !== HandModelMeshNames.Skeleton) {
        if (selectedObject.name !== name) {
          this.clearPrevSelectedObjectHighlight();
        } else {
          material.updateHighlight(MuscleHighlight.HasHighlight);

          this.sceneStore.outlinePass.selectedObjects = [selectedObject];
        }
      }
    } else {
      this.clearPrevSelectedObjectHighlight();

      this.sceneStore.outlinePass.selectedObjects = [];
    }
  }
}
