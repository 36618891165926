// eslint-disable-next-line simple-import-sort/imports
import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { RouterPathEnum } from 'shared';

import LoginPage from './pages/LoginPage';
import ScenePage2 from './pages/ScenePage2';
import DoctorPersonalPage from './pages/DoctorPersonalPage';
import { DashboardPage, ErrorPage, MainPage, RootLayout } from './pages';

import './App.css';
import 'sanitize.css';
import ProfilePage from 'pages/ProfilePage/ProfilePage';

const router = createBrowserRouter([
  {
    path: RouterPathEnum.Main,
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <MainPage />,
      },
      {
        path: RouterPathEnum.Scene,
        element: <ScenePage2 />,
      },
      {
        path: RouterPathEnum.Dashboard,
        element: <DashboardPage />,
      },
      {
        path: RouterPathEnum.DoctorPage,
        element: <DoctorPersonalPage/>,
      },
      {
        path: RouterPathEnum.Profile,
        element: <ProfilePage/>,
      },
    ],
  },
  {
    path: RouterPathEnum.Auth,
    element: <LoginPage />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
