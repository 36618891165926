import {
  IPatientDates,
  IPatientGetAttempt,
  IPatientGetCSV,
  IPatientGetDto,
} from 'shared/interfaces';

import BaseApiService from './BaseApiService';

class PatientsApiService extends BaseApiService {
  constructor() {
    super('api');
  }

  getPatients(): Promise<IPatientGetDto[]> {
    return this.GETBYID('userlist/');
  }

  getDoctors(): Promise<IPatientGetDto[]> {
    return this.GETBYID('doctor-list/');
  }

  getPatientsOnly(): Promise<IPatientGetDto[]> {
    return this.GETBYID('doctor-patient/');
  }

  postPatientInfo(patientId: number): Promise<IPatientDates[]> {
    return this.POST(`userdata/`, { userid: patientId });
  }

  postPatientDynamic(id: number, level: string): Promise<IPatientGetCSV[]> {
    return this.POST(`userlist_csv_dinamic/`, { id: id, level_name: level });
  }

  postPatientAttemptInfo(patientId: number, date: string): Promise<IPatientGetAttempt[]> {
    return this.POST(`userlist_check/`, { userid: patientId, date: date });
  }

  postPatientDateCSV(id: number, level: string): Promise<IPatientGetCSV[]> {
    return this.POST(`userlist_csv/`, { id: id, level_name: level });
  }

  postUserDataGesture(user: string, level: string): Promise<IPatientGetCSV[]> {
    return this.POST(`userdata_gesture/`, { user: user, level_name: level });
  }

  getProfile(): Promise<any> {
    return this.GETBYID(`profile/`, {});
  }

  postPatientProfile(id: number): Promise<any> {
    return this.GETBYID(`profile/${id}`);
  }

  postTask(files: [number]): Promise<any[]> {
    return this.POST(`task/`, { files: files });
  }
}

export default new PatientsApiService();
