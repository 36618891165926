import { makeAutoObservable } from 'mobx';

import { IGestureInfoDto, IGestureInfoDtoAdvance } from 'shared/interfaces';


export class BoxPlotModel {
  public name: string;

  public jointData: any;
  public jointData2?: any;
  public jointData3?: any;


  constructor(jointName: string, dto: IGestureInfoDto[] | IGestureInfoDtoAdvance,dto2?: IGestureInfoDto[] | IGestureInfoDtoAdvance,dto3?: IGestureInfoDto[] | IGestureInfoDtoAdvance) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.name = jointName;
    this.jointData = dto;
    this.jointData2 = dto2;
    this.jointData3 = dto3;
  }

}
