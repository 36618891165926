import { makeAutoObservable } from 'mobx';

import { DataThird } from 'shared/models/dashboardModels/DataThird';

import { IGestureInfoDto, IGestureInfoDtoAdvance, IJointInfoDto } from '../shared/interfaces';
import {
  BoxPlotModel,
  DataGestureModel,
  DataGestureModel2,
  DataGestureModel3,
  DataPowerModel,
  DataPowerModel2,
  DataPowerModel3,
  DataProcessingModel,
  DataSecond,
  JointModel,
} from '../shared/models/dashboardModels';

class DashboardStore {
  public dataLoader: DataProcessingModel = new DataProcessingModel();

  public dataSecondLoader: DataSecond = new DataSecond();

  public dataThirdLoader: DataThird = new DataThird();

  public PowerLoader: DataPowerModel = new DataPowerModel();

  public Power2Loader: DataPowerModel2 = new DataPowerModel2();

  public Power3Loader: DataPowerModel3 = new DataPowerModel3();

  public GestureLoader: DataGestureModel = new DataGestureModel();

  public GestureLoader2: DataGestureModel2 = new DataGestureModel2();

  public GestureLoader3: DataGestureModel3 = new DataGestureModel3();

  public selectedJoint: JointModel | null = null;

  public selectedGesture: BoxPlotModel | null = null;

  public jointHand: 'left'| 'right' = 'left';

  public Switcher: boolean = false;

  public selectedGestureAdvanceSetting: boolean = false;

  public sizeRadarChartValue: number = 10;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public setSelectedJoint(jointName: string, dto: IJointInfoDto,dto2?: IJointInfoDto, dto3?: IJointInfoDto) {
    this.selectedJoint = new JointModel(jointName, dto, dto2, dto3);
  }

  public setSelectedGesture(jointName: string, dto: IGestureInfoDto[] | IGestureInfoDtoAdvance,dto2?: IGestureInfoDto[] | IGestureInfoDtoAdvance,dto3?: IGestureInfoDto[] | IGestureInfoDtoAdvance) {
    this.selectedGesture = new BoxPlotModel(jointName, dto, dto2, dto3);
  }

  public setJointUpdate(hand: 'left'| 'right') {
    this.jointHand = hand;
  }

  public setSizeRadarChartValue(value: number) {
    this.sizeRadarChartValue = value;
  }

  public setGestureAdvanceSetting(set: boolean) {
    this.selectedGestureAdvanceSetting = set;
  }

  public setSwitcher(switcher: boolean) {
    this.Switcher = switcher;
  }

  public clearSelectedJoint() {
    this.selectedJoint = null;
  }
}

export default new DashboardStore();
