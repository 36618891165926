import { TableBody, TableCell, TableRow } from '@mui/material';
import block from 'bem-cn';
import { observer } from 'mobx-react';

import { dashboardStore } from 'stores';

import { ERROR_COLOR, JOINTS, SUCCESS_COLOR, WARNING_COLOR } from 'shared/constants';

import './StatsTable.scss';

const GRADIENT_STEPS = 20;

const getPercentageString = (ooc: number[]) => {
  const oocPercentage = ooc[ooc.length - 1] * 100;

  return oocPercentage.toFixed(2) + '%';
};

const getGradientSteps = (ooc: number[]): null[] => {
  const oocPercentage = ooc[ooc.length - 1] * 100;
  const stepsCount = Math.min(Math.ceil(oocPercentage), GRADIENT_STEPS / 2) * 2;

  return new Array(stepsCount).fill(null);
};

const getGradientColor = (currentStep: number) => {
  return currentStep < GRADIENT_STEPS * 0.4
    ? ERROR_COLOR
    : currentStep < GRADIENT_STEPS * 0.7
    ? WARNING_COLOR
    : SUCCESS_COLOR;
};

const cnStatsTable = block('StatsTable');

const StatsTable = () => {
  return (
    <TableBody>
      {JOINTS.map((joint, index) => {
        const jointValues = dashboardStore.dataLoader.data[joint];
        const jointValues2 = dashboardStore.dataSecondLoader.data[joint];
        const jointValues3 = dashboardStore.dataThirdLoader.data[joint];
        
        if (!jointValues) return null;

        const oocPercentageStr = getPercentageString(jointValues.ooc);
        const oocPercentageStr2 = jointValues2 && getPercentageString(jointValues2?.ooc);
        const oocPercentageStr3 =  jointValues3 &&getPercentageString(jointValues3?.ooc);

        function sumJointValues(...arrays: any[]) {
          const n = arrays.reduce((max, xs) => Math.max(max, xs.length), 0);
          const result = Array.from({ length: n });
          return result.map((_, i) => arrays.map(xs => xs[i] || 0).reduce((sum, x) => (sum + x)/3, 0));//среднее арифметическое значение каждого элемента массивов
        }
        const gradientSteps = getGradientSteps((jointValues2 && jointValues3) ? sumJointValues(jointValues.ooc,jointValues2.ooc,jointValues3.ooc):jointValues.ooc);

        const selectRow = () => {
          dashboardStore.setSelectedJoint(joint, jointValues,jointValues2,jointValues3);
        };

        return (
          <TableRow
            key={joint + index}
            onClick={selectRow}
            className={cnStatsTable('row', {
              selected: dashboardStore.selectedJoint?.name === joint,
            }).toString()}
          >
            <TableCell>{joint}</TableCell>
            <TableCell align="center">{oocPercentageStr}</TableCell>
            {jointValues2 && <TableCell align="center">{oocPercentageStr2}</TableCell>}
            {jointValues3 && <TableCell align="center">{oocPercentageStr3}</TableCell>}
            <TableCell align="center">
              <div
                className={cnStatsTable('gradient')}
                style={{ width: `${GRADIENT_STEPS * 6}px` }}
              >
                {gradientSteps.map((_, index) => {
                  return (
                    <div
                      key={index}
                      className={cnStatsTable('gradientStep')}
                      style={{ backgroundColor: getGradientColor(index + 1) }}
                    ></div>
                  );
                })}
              </div>
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default observer(StatsTable);
