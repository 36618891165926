import { List, ListItemButton, ListItemText } from '@mui/material';
import block from 'bem-cn';
import { observer } from 'mobx-react';

import './Levels.scss';

const cnLevels = block('Levels');

interface LevelsProps {
  onClick: (lvl:any)=> void;
}

const Levels = ({onClick}:LevelsProps) => {
  const Levels = [1, 2, 3, 4, 5, 6];

  return (
    <>
      
       
        <div className={cnLevels('wrap').toString()}>
          Уровни:
          <List className={cnLevels('nav').toString()}>
            {Levels.map((level: number) => {
              return (
                <ListItemButton
                  key={level}
                  className={cnLevels('lvls').toString()}
                  onClick={() => onClick(level)}
                >
                  <ListItemText primary={level} />
                </ListItemButton>
              );
            })}
          </List>
        </div>
      
    </>
  );
};

export default observer(Levels);
