import { useEffect, useRef } from 'react';
import { Button } from '@mui/material';
import block from 'bem-cn';
import { observer } from 'mobx-react';

import { sceneStore } from 'stores';

import './ScenePage2.scss';

const cnScenePage = block('ScenePage2');

const ScenePage2 = () => {
  const sceneWrapperRef = useRef<HTMLDivElement>(null);

  const checkIntersection = (e: PointerEvent) => {
    if (!sceneWrapperRef.current || !e.isPrimary) return;
  };

  useEffect(() => {
    if (sceneWrapperRef.current) {
      sceneStore.initScene(sceneWrapperRef.current);
      sceneStore.initModel();

      
      sceneWrapperRef.current.addEventListener('pointermove', checkIntersection);
    }

    return () => {
      sceneStore.deInit();

      if (sceneWrapperRef.current)
        sceneWrapperRef.current.removeEventListener('pointermove', checkIntersection);
    };
  }, [sceneWrapperRef.current]);

  useEffect(() => {
    const onResize = () => {
      if (sceneWrapperRef.current) {
        sceneStore.updateSceneSizes(
          sceneWrapperRef.current.clientWidth,
          sceneWrapperRef.current.clientHeight,
        );
      }
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  useEffect(()=>{
    console.log(sceneStore.Musculs)
  },[sceneStore.Musculs])

  return <><div className='dashboard'>{sceneStore.Musculs?.map((el:any)=>{
    return <Button key={el} variant='outlined' onClick={()=>sceneStore.handModel?.updateModelByBtns(el)}>{el}</Button>
  })}</div><div className={cnScenePage()} ref={sceneWrapperRef}><div className='keyMscle'>Выбраный элемент:<br/>{sceneStore.muscle.map((el: any)=>el + ', ')}</div></div></>;
};

export default observer(ScenePage2);
