import { ChangeEvent, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Stack, TextField } from '@mui/material';
import block from 'bem-cn';
import { observer } from 'mobx-react';

import { authStore } from 'stores';

import { Loader } from 'components';
import { RouterPathEnum } from 'shared';

import './LoginPage.scss';

const cnLoginPage = block('LoginPage');



const LoginPage = () => {
  const navigate = useNavigate();

  const authorize = async () => {
    await authStore.signIn();

    if (authStore.currentUser) navigate(RouterPathEnum.Main);
  };

  const [HasValue, setHasValue] = useState(false);
  const [HasValue2, setHasValue2] = useState(false);
  const [HasValuePass, setHasValuePass] = useState(false);

  const makeAnimationStartHandler = (stateSetter: { (value: SetStateAction<boolean>): void; (arg0: boolean): void; }) => (e:any) => {
    const autofilled = !!e.target?.matches('*:-webkit-autofill');

      if(e.target.value != false) {
        setHasValue2(true);
        stateSetter(autofilled);
      }
    
    if (e.animationName === 'mui-auto-fill') {
      stateSetter(autofilled);
    }

    if (e.animationName === 'mui-auto-fill-cancel') {
      stateSetter(autofilled);
    }
    
  };

  const onChangeUsername = (event: ChangeEvent<HTMLInputElement>) => {
    authStore.setUsername(event.target.value);
    if(event.target.value) {
      setHasValue2(true);
    } else{
      setHasValue2(false);
    }
    
  };
  
  const onChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
    authStore.setPassword(event.target.value);

    if(event.target.value) {
      setHasValuePass(true);
    } else{
      setHasValuePass(false);
    }
  };

  return (
    <Box className={`${cnLoginPage.toString()}`}>
      <Loader visible={authStore.isLoading} />

      <Stack
        className={cnLoginPage('form').toString()}
        direction={'column'}
        gap={4}
        padding={4}
        borderRadius={2}
      >
        <TextField
          label="Username"
          value={authStore.username}
          onChange={onChangeUsername}
          inputProps={{
            onAnimationStart: makeAnimationStartHandler(setHasValue),
          }}
          InputLabelProps={{
            shrink: (HasValue2 || HasValue),
          }}
        ></TextField>

        <TextField
          label="Password"
          type={'password'}
          value={authStore.password}
          onChange={onChangePassword}
          InputLabelProps={{
            shrink: (HasValue2 || HasValue || HasValuePass),
          }}
        ></TextField>

        <Button variant="contained" onClick={authorize}>
          Sign in
        </Button>
      </Stack>
    </Box>
  );
};

export default observer(LoginPage);