/* import { useEffect } from 'react'; */

import { useEffect } from 'react';
import { Paper, Table, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import block from 'bem-cn';
import { observer } from 'mobx-react';

import { dashboardStore } from 'stores';

import { PanelWrapper } from 'components';

import GestureTable from './components/GestureTable/GestureTable';
import PowerTable from './components/PowerTable/PowerTable';
import StatsTable from './components/StatsTable/StatsTable';

import './StatisticsTable.scss';

const cnStatisticsTable = block('StatisticsTable');

const StatisticsTable = (props?: { tab?: string; columnsNames?: any }) => {

  const selectRow = (joint: string, jointValues: any,jointValues2?: any,jointValues3?: any) => {
    dashboardStore.setSelectedJoint(joint, jointValues,jointValues2,jointValues3);
  };

  useEffect(() => {
    if (dashboardStore.selectedJoint?.name) {
      selectRow(
        dashboardStore.selectedJoint!.name,
        dashboardStore.dataLoader.data[dashboardStore.selectedJoint!.name],
        dashboardStore.dataSecondLoader.data[dashboardStore.selectedJoint!.name],
        dashboardStore.dataThirdLoader.data[dashboardStore.selectedJoint!.name],
      );
    }
  }, [dashboardStore.jointHand,dashboardStore.selectedGestureAdvanceSetting]);
  const dynamicCheckValue = Object.keys(dashboardStore.dataThirdLoader.data).length;
  const dynamicCheckValue2 = Object.keys(dashboardStore.dataSecondLoader.data).length;

  return (
    <PanelWrapper className={cnStatisticsTable()}>
      <TableContainer component={Paper} className={cnStatisticsTable('table').toString()}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>{!!props?.columnsNames ? props?.columnsNames[0] : 'Сустав'}</TableCell>
              <TableCell align="center">
                {!!props?.columnsNames ? props?.columnsNames[1] : 'Длительность'}
              </TableCell>
              <TableCell className={!dynamicCheckValue ?cnStatisticsTable('MuiTableCell-sizeSmall').toString():''} align="center">{!!props?.columnsNames ? props?.columnsNames[2] : 'Длительность 2'}</TableCell>
              <TableCell className={!dynamicCheckValue2 ?cnStatisticsTable('MuiTableCell-sizeSmall').toString():''} align="center">{!!props?.columnsNames ? props?.columnsNames[3] : 'Длительность 3'}</TableCell>
              {props?.tab != 'gesture' && <TableCell align="center">Активность</TableCell>}
            
            </TableRow>
          </TableHead>
          {props?.tab === 'power' ? (
            <PowerTable />
          ) : props?.tab === 'gesture' ? (
            <GestureTable />
          ) : (
            <StatsTable />
          )}
        </Table>
      </TableContainer>
    </PanelWrapper>
  );
};

export default observer(StatisticsTable);
